<template>
    <div class="c-box" :class="boxBg(comp.settings.bg)">
        <div class="c-box__content">
            <page-component v-for="(childComp, index) in comp.settings.layout[0].children" :comp="childComp" :key="index"/>
        </div>
    </div>
</template>

<script>
  import PageComponent from '@/components/PageComponent'

  export default {
    name: 'Comp_box',
    props: {
      comp: Object
    },

    methods: {
      boxBg (color) {
        if (color) return 'c-box--' + color.toLowerCase()
      }
    },

    components: {
      PageComponent
    }
  }
</script>
